.checkbox :nth-child(2) {
  margin-top: 3px !important;
  border-radius: 4px !important;
  border-width: 1px !important;
  border-color: var(--chakra-colors-h-mid-grey) !important;
  background-color: white;
}

.checkbox svg {
  stroke-width: 2.5 !important;
  font-size: .875rem;
  stroke-linecap: round;
}
