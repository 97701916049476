.toastrWrapper {
  position: fixed;
  left: calc(50vw - 196px);
  bottom: 40%;
  z-index: 99999;
}

.toastrElement {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1rem;
  z-index: 99999;
  padding: 40px 32px;
  padding-top: 52px;
  line-height: 22px;
  border-radius: 8px;
  transition: all 2s ease 0s;
  box-shadow: 0px 10px 70px rgb(0 0 0 / 15%);
  width: 372px;
  text-align: center;
}

.toastrTitleWrapper {
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  color: #424242;
  line-height: 1.75rem;
}

.toastrMsgWrapper {
  display: block;
  font-size: 1.25rem;
  font-weight: 400;
  margin-bottom: 2.5rem;
  color: #6C6C6C;
}

.closeIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: 8px;
  cursor: pointer;
}

.toastrMsgWrapper::first-letter {
  text-transform: capitalize;
}

.button {
  border-radius: 32px;
  color: white;
  padding: 8px 16px;
  background-color: #F15F61;
  font-weight: 500;
  width: 70%;
}

.toastrElement.success {
  color: #ffffff;
  background-color: #38a169;
}

.toastrElement.info {
  color: #ffffff;
  background-color: #3182CE;
}


.toastrElement.error {
  background-color: white;
  color: #F15F61;
}

.toastrElement.warning {
  color: #ffffff;
  background-color: #DD6B20;
}

@media only screen and (max-width: 600px) {
  .toastrElement {
    width: 100%;
    min-width: unset;
  }
}
