.suitability-markdown p {
  background: #f6f6f6;
  border-radius: 6px;
  padding: 0.5rem 0.75rem;
}

.suitability-markdown ul {
  padding-left: 1rem;
}

.suitability-markdown a {
  text-decoration: underline;
}

.suitability-markdown strong {
  font-weight: 500;
}

.suitability-question-text-markdown p {
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 500;
}

.suitability-question-text-markdown p span {
  font-size: 1rem;
  font-weight: 400;
}

.suitability-question-text-markdown h2 {
  margin-top: 1.8rem;
  font-size: 1.125rem;
  line-height: 1.375rem;
  font-weight: 500;
}

.suitability-question-text-markdown h3 {
  font-size: 0.813rem;
  font-weight: 500;
}

.suitability-question-text-markdown h6 {
  margin-top: 1rem;
  line-height: 1.5rem;
}
