.company-eligibility-criteria-markdown ul {
  padding-left: 1.5rem;
}

.company-eligibility-criteria-markdown ol {
  padding-left: 1.5rem;
}

.company-eligibility-criteria-markdown a {
  font-weight: 500;
}
