.textArea {
  border-radius: 6px;
  padding: 8px 16px;
  white-space: pre-line;
  background-color: white;
  color: #323232;
  width: 100%;
  line-height: 22px;
  border-color: #818181;
  border-width: 1px;
  resize: none;
  appearance: none;
  box-sizing: border-box;
  outline: none;
}

.textArea::placeholder {
  color: #575757;
}
